import React, { useState } from 'react';
import { formatPrice } from '../helper/contractHelper';
import { useAccountStats, useHomeStats } from '../hooks/useStats';
import StarterPlan from './StarterPlan';
import ForeverPlan from './ForeverPlan';



export default function Saving() {

    const daysTab = {
        links: [
            {
                id: 1,
                className: "StakeTablinks",
                content: "GN Starter",
                apy: "(Lock 365 Days)",
                timestamp: 90,
                c_id: 0
            },
            {
                id: 2,
                className: "StakeTablinks",
                content: "GN Forever",
                apy: "(Income 30 Years)",
                timestamp: 180,
                c_id: 1
            }
        ],
        activeLink: null
    };

    const [dayId, setDayId] = useState(1);
    const [updater, setUpdater] = useState(1);
    const stats = useHomeStats(updater);
    const accStats = useAccountStats(updater);

    const handleChangeDay = (id) => {
        setDayId(id);
    }

    // const handleApprove = async (e) => {
    //     e.preventDefault();
    //     if (account) {
    //         if (chainId) {
    //             try {
    //                 setLoading(true);

    //                 let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;

    //                 let tokenContract = getContract(tokenAbi, stats.tokenAddress, library);
    //                 let amount = parseEther('100000000000000000000000').toString();
    //                 let tx = await tokenContract.approve(tokenStakingAddress, amount, { 'from': account });
    //                 const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 15000));
    //                 toast.promise(
    //                     resolveAfter3Sec,
    //                     {
    //                         pending: 'Waiting for confirmation 👌',
    //                     }
    //                 )
    //                 var interval = setInterval(async function () {
    //                     let web3 = getWeb3(chainId);
    //                     var response = await web3.eth.getTransactionReceipt(tx.hash);
    //                     if (response != null) {
    //                         clearInterval(interval)
    //                         if (response.status === true) {
    //                             toast.success('success ! your last transaction is success 👍');
    //                             setUpdater(new Date());
    //                             setLoading(false);
    //                         }
    //                         else if (response.status === false) {
    //                             toast.error('error ! Your last transaction is failed.');
    //                             setUpdater(new Date());
    //                             setLoading(false);
    //                         }
    //                         else {
    //                             toast.error('error ! something went wrong.');
    //                             setUpdater(new Date());
    //                             setLoading(false);
    //                         }
    //                     }
    //                 }, 5000);

    //             }
    //             catch (err) {
    //                 toast.error(err.reason ? err.reason : err.message);
    //                 setLoading(false);
    //             }
    //         }
    //         else {
    //             toast.error('Please select Smart Chain Network !');
    //             setLoading(false);
    //         }

    //     }
    //     else {
    //         toast.error('Please Connect Wallet!');
    //         setLoading(false);
    //     }
    // }

    // const handleStake = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     try {
    //         if (amount > 0 && !isNaN(amount)) {
    //             if (account) {
    //                 if (chainId) {
    //                     if (parseFloat(accStats.balance) >= parseFloat(amount)) {
    //                         if (typeof dayId !== 'undefined' || dayId !== 0) {
    //                             let lockupDuration = daysTab.links[dayId - 1].c_id;
    //                             let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
    //                             let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
    //                             let stakeAmount = ethers.utils.parseUnits(amount.toString(), stats.tokenDeciamls);
    //                             let tx = await stakeContract.deposit(stakeAmount, lockupDuration, { 'from': account });
    //                             toast.loading('Waiting for confirmation...');

    //                             var interval = setInterval(async function () {
    //                                 let web3 = getWeb3(chainId);
    //                                 var response = await web3.eth.getTransactionReceipt(tx.hash);
    //                                 if (response != null) {
    //                                     clearInterval(interval)
    //                                     if (response.status === true) {
    //                                         toast.dismiss();
    //                                         toast.success('success ! your last transaction is success 👍');
    //                                         setUpdater(new Date());
    //                                         setLoading(false);
    //                                     }
    //                                     else if (response.status === false) {
    //                                         toast.dismiss();
    //                                         toast.error('error ! Your last transaction is failed.');
    //                                         setUpdater(new Date());
    //                                         setLoading(false);
    //                                     }
    //                                     else {
    //                                         toast.dismiss();
    //                                         toast.error('error ! something went wrong.');
    //                                         setUpdater(new Date());
    //                                         setLoading(false);
    //                                     }
    //                                 }
    //                             }, 5000);
    //                         }
    //                         else {
    //                             toast.dismiss();
    //                             toast.error('Please select days !');
    //                             setLoading(false);
    //                         }
    //                     }
    //                     else {
    //                         toast.dismiss();
    //                         toast.error('you don\'t have enough balance !');
    //                         setLoading(false);
    //                     }

    //                 }
    //                 else {
    //                     toast.dismiss();
    //                     toast.error('Please select Smart Chain Network !');
    //                     setLoading(false);
    //                 }
    //             }
    //             else {
    //                 toast.dismiss();
    //                 toast.error('Please Connect Wallet!');
    //                 setLoading(false);
    //             }
    //         }
    //         else {
    //             toast.dismiss();
    //             toast.error('Please Enter Valid Amount !');
    //             setLoading(false);
    //         }
    //     }
    //     catch (err) {
    //         toast.dismiss();
    //         console.log(err);
    //         toast.error(err.reason ? err.reason : err.message);
    //         setLoading(false);
    //     }
    // }

    return (
        <div className="staking-overflow py-4 py-sm-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link fs-21 active" id="pills-staking-overflow-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-staking-overflow" type="button" role="tab"
                                    aria-controls="pills-staking-overflow"
                                    aria-selected="true">Mining
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content rounded-8 mt-4 mt-sm-5" id="pills-tabContent">
                            {/* <!--STAKING-OVERFLOW--> */}
                            <div className="tab-pane fade show active" id="pills-staking-overflow" role="tabpanel"
                                aria-labelledby="pills-staking-overflow-tab">
                                <div className="row gx-4 gy-3 gy-sm-0 gx-sm-5 mb-4 mb-sm-5 ">
                                    <div className="col-sm-4">
                                        <div className="top-content d-flex flex-column gap-3 text-white justify-content-center align-items-center">
                                            <p>MGP Price Per 1 MMSC</p>
                                            <div className="fs-28 fw-bold">{parseFloat(parseFloat(parseFloat( parseFloat(stats.userCount) / parseFloat(4000)) + 5).toFixed(8))}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="top-content d-flex flex-column gap-3 text-white justify-content-center align-items-center">
                                            <p>Total User</p>
                                            <div className="fs-28 fw-bold">{stats.userCount ? formatPrice(stats.userCount) : 0}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="top-content d-flex flex-column gap-3 text-white justify-content-center align-items-center">
                                            <p>Total Token Mining</p>
                                            <div className="fs-28 fw-bold">{stats.totalSaving ? formatPrice(stats.totalSaving) : 0}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-100">
                                    <div className="col-12">
                                        <div className="fs-28 mb-sm-5 mb-4">Mining Submission</div>
                                        <div className="d-flex staking-days flex-wrap flex-lg-nowrap">
                                            {daysTab.links.map(link => {
                                                return (
                                                    <div key={link.id} onClick={(e) => { handleChangeDay(link.id) }} className={`${link.id === dayId ? " active" : ""} align-items-center w-100 d-flex flex-column item justify-content-center rounded-8 position-relative overflow-hidden`}>
                                                        <div className="fs-21">{link.content}</div>
                                                        {/* <p className='fs-21'>{link.apy}</p> */}
                                                        <i className="fa-solid fa-check text-white position-absolute"
                                                        ></i>
                                                    </div>

                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>

                                {dayId === 1 ?
                                    (
                                        <StarterPlan updater={updater} setUpdater={setUpdater} accStats={accStats} />
                                    ) : (
                                        <ForeverPlan updater={updater} setUpdater={setUpdater} accStats={accStats} />
                                    )
                                }


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
