import React, { useState } from 'react';
import sLogoImg from '../images/s-logo.png';
import { useSPlanStats } from '../hooks/useStats';
import { formatPrice } from '../helper/contractHelper';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { TOKEN_DECIMALS } from '../helper/constant';
import { toast } from 'react-toastify';
import { getContract } from '../helper/contractHelper';
import tokenAbi from '../json/token.json';
import { contract, DEFAULT_CHAIN } from '../helper/constant';
import { getWeb3 } from '../helper/connectors';
import stakeAbi from '../json/tokenstake.json';



export default function StarterPlan({ updater, setUpdater, accStats }) {
    const stats = useSPlanStats(updater);
    const { account, chainId, library } = useWeb3React();
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const [loading, setLoading] = useState(false);

    const handleApprove = async (e) => {
        e.preventDefault();
        if (account) {
            if (chainId) {
                try {
                    setLoading(true);
                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS, library);
                    let amount = ethers.utils.parseUnits(stats.starterPlanInvest.toString(), TOKEN_DECIMALS)
                    let tx = await tokenContract.approve(contract[DEFAULT_CHAIN].STAKE_ADDRESS, amount, { 'from': account });
                    toast.loading('waiting for confirmation..');
                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                        }
                    }, 5000);

                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.dismiss();
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.dismiss();
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleStake = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            if (account) {
                if (chainId) {
                    if (parseFloat(accStats.tokenBalance) >= parseFloat(stats.starterPlanInvest)) {
                        let stakeContract = getContract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS, library);
                        let tx = await stakeContract.StarterMiningPlan({ 'from': account });
                        toast.loading('Waiting for confirmation...');

                        var interval = setInterval(async function () {
                            let web3 = getWeb3();
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('success ! your last transaction is success 👍');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                            }
                        }, 5000);

                    }
                    else {
                        toast.dismiss();
                        toast.error('you don\'t have enough balance !');
                        setLoading(false);
                    }

                }
                else {
                    toast.dismiss();
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.dismiss();
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.dismiss();
            console.log(err);
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    const handleWithdraw = async () => {
        setLoading(true);
        try {

            if (account) {
                if (chainId) {

                    let stakeContract = getContract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS, library);
                    let tx = await stakeContract.withdrawStarterPlan({ 'from': account });
                    toast.loading('Waiting for confirmation...');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                        }
                    }, 5000);



                }
                else {
                    toast.dismiss();
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.dismiss();
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.dismiss();
            console.log(err);
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    return (
        <div className="row">
            <div className="col-12">
                <div className="align-items-center d-flex flex-wrap flex-sm-nowrap w-100 gap-2 gap-sm-4">
                    <div className="fs-21 flex-shrink-0">
                        Amount to be Mining:
                    </div>
                    <div className="input-staked d-flex w-100 border rounded-8 p-2">
                        <input type="text" className="form-control border-0" value={stats.starterPlanInvest} readOnly={true} />
                        <button className='max-btn' type='button'>MAX Plan</button>
                        <img src={sLogoImg} className="img-fluid" alt="s-logo" style={{ "width": "45px" }} />
                    </div>
                </div>
                {/* <h5 className='fs-21 mt-2 fw-normal mb-4 mb-sm-5 ml-254 text-danger mt-3'><small>{error_msg}</small></h5> */}
                <h5 className='fs-21 mt-2 fw-normal mb-4 mb-sm-5 ml-254 text-danger mt-3'><small></small></h5>

                <div className="fs-21 mt-2 fw-normal mb-4 mb-sm-5 ml-254">Available: {accStats.tokenBalance ? formatPrice(accStats.tokenBalance) : 0} MCASH</div>

                <div className="fs-21 mt-2 fw-bold mb-3 mb-sm-4 ml-254 ml-0">Mining Summary</div>
                {accStats.sPlan ?
                    (
                        <div className="ml-254 ml-0">
                            <div className="table-grid">
                                <div>
                                    <div>Status</div>
                                    <div>:</div>
                                    <div><span class="badge badge-success">{accStats.sPlanClaimed ? 'withdrawn' : 'Activated'}</span></div>
                                </div>
                                <div>
                                    <div>Duration</div>
                                    <div>:</div>
                                    <div> {stats.starter_plan ? stats.starter_plan : '365'} Days</div>
                                    {/* <div> 365 Days</div> */}

                                </div>
                                <div>
                                    <div>Time Left</div>
                                    <div>:</div>
                                    <div>{accStats.sPlan_endTime - Math.floor(new Date().getTime() / 1000.0) > 0 ? Math.round(Math.abs((accStats.sPlan_endTime - Math.floor(new Date().getTime() / 1000.0)) / 86400)) : 0} Days</div>
                                </div>
                                <div>
                                    <div>Mining Return</div>
                                    <div>:</div>
                                    <div>{stats.starterPlanInvest ? formatPrice(parseFloat(stats.starterPlanInvest) - parseFloat(stats.withdrawFees)) : 0} MCASH</div>
                                </div>
                                <div>
                                    <div>Withdraw Fees</div>
                                    <div>:</div>
                                    <div>{stats.withdrawFees ? stats.withdrawFees : 0} MCASH</div>
                                </div>
                                <div>
                                    <div>Start Date</div>
                                    <div>:</div>
                                    <div>{accStats.sPlan_startTime ? new Date(accStats.sPlan_startTime * 1000).toString() : ''}</div>
                                </div>
                                <div>
                                    <div>End Date</div>
                                    <div>:</div>
                                    <div>{accStats.sPlan_endTime ? new Date(accStats.sPlan_endTime * 1000).toString() : ''}</div>
                                </div>
                            </div>
                            {Math.floor(new Date().getTime() / 1000.0) >= parseInt(accStats.sPlan_endTime) && !accStats.sPlanClaimed ?
                                (
                                    <button onClick={() => handleWithdraw()} className="confirm-btn btn btn-primary h-48 w-100 my-4 my-sm-5">
                                        {loading ? 'LOADING...' : 'WITHDRAW'}
                                    </button>) : (
                                    <>
                                        <button disabled={true} className="confirm-btn btn btn-primary h-48 w-100 mt-5 mb-1">
                                            Withdraw
                                        </button>
                                        {!accStats.sPlanClaimed &&
                                            <p className='text-danger text-sm'>Note : your plan already activated , you can withdraw after End Date.</p>
                                        }
                                    </>
                                )}


                        </div>
                    )

                    : (
                        <div className="ml-254 ml-0">
                            <div className="table-grid">
                                <div>
                                    <div>Duration</div>
                                    <div>:</div>
                                    {/* <div> {stats.starter_plan ? stats.starter_plan : '365'} Days</div> */}
                                    <div> 365 Days</div>

                                </div>
                                <div>
                                    <div>Mining Amount</div>
                                    <div>:</div>
                                    <div>{stats.starterPlanInvest ? formatPrice(stats.starterPlanInvest) : 0} MCASH </div>
                                </div>
                                <div>
                                    <div>Mining Return</div>
                                    <div>:</div>
                                    <div>{stats.starterPlanInvest ? formatPrice(parseFloat(stats.starterPlanInvest) - parseFloat(stats.withdrawFees)) : 0} MCASH</div>
                                </div>
                                <div>
                                    <div>Withdraw Fees</div>
                                    <div>:</div>
                                    <div>{stats.withdrawFees ? stats.withdrawFees : 0} MCASH</div>
                                </div>
                                <div>
                                    <div>Start Date</div>
                                    <div>:</div>
                                    <div>{today.toString()}</div>
                                </div>
                                <div>
                                    <div>End Date</div>
                                    <div>:</div>
                                    <div>{new Date(parseInt(timeElapsed) + parseInt(stats.starter_plan * 86400 * 1000)).toString()}</div>
                                </div>
                            </div>
                            {
                                parseFloat(accStats.isApprove) < parseFloat(stats.starterPlanInvest) ? (
                                    <button disabled={loading} className="confirm-btn btn btn-primary h-48 w-100 my-4 my-sm-5" onClick={(e) => handleApprove(e)}>
                                        {loading ? 'LOADING...' : 'APPROVE'}
                                    </button>
                                ) : (
                                    <button disabled={loading} className="confirm-btn btn btn-primary h-48 w-100 my-4 my-sm-5" onClick={(e) => handleStake(e)}>
                                        {loading ? 'LOADING...' : 'MINING NOW'}
                                    </button>
                                )
                            }
                        </div>
                    )}

            </div>
        </div>
    )
}
