export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}


export const contract = {
  56: {
    MULTICALL_ADDRESS: "0xca11bde05977b3631167028862be2a173976ca11",
    STAKE_ADDRESS: "0xB0fb5739dA1198329b0241CB6721da22805CBEcc",
    TOKEN_ADDRESS : "0xbdd59c287f8a18304227dcb2661cbaa5e6b5d8a4"
  }
}

export const DEFAULT_CHAIN = 56;
export const TOKEN_DECIMALS = 8;
