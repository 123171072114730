import React from 'react';
import './footer.css';
import logoBlack from '../images/logo-black.png';
import bscscan50x50 from '../images/bscscan50x50.png';
// import line40x40 from '../images/line40x40.png';
import twitterCopy from '../images/twitter-Copy.png';
import telegramfw from '../images/telegram.fw.png';
// import youtube1fw from '../images/youtube-1.fw.png';
import githubfw from '../images/github.fw.png';
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <React.Fragment>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12">
                            <img src={logoBlack} className="logo" alt="" />
                            <p>Earn passive income from mining  Mcash up to 30 years. With the best DAPP Platform. Withdraw your rewards at any time. Now it's time to enjoy your life and finally let your money work for you every day.</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <h2 class="foo-title">Main Menu</h2>
                            <ul className='foo-list'>
                                <li>

                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mining">
                                        Mining
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://mcash-exchangepay.com/" target="_balnk" rel="noreferrer">
                                        Website
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href=" https://app.dodoex.io/?from=BNB&to=MCASH">
                                        BUY TOKENS
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-12">
                            <h2 class="foo-title">Use Links</h2>
                            <ul className='foo-list'>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://mms-soilminerals.com/">
                                        MMS Coin
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://mcash-exchangepay.com/">
                                        MMS Cash
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.mcash-saving.com/">
                                        Mcash Saving
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://saving-mmsc.com/">
                                        Mcoin Saving
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.mgpmalls.com/">
                                        MGP Shopping
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-12 widget">
                            <h2 class="foo-title">Contact us</h2>
                            <div className="social-icon gap-3">
                                <a target="_blank" rel="noreferrer" href="https://bscscan.com/token/0xbdD59c287F8A18304227DCb2661CbAa5E6b5D8a4" className="caption-color">
                                    <img src={bscscan50x50} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://t.me/+seMX1nx2Bgk4ZTFl" className="caption-color">
                                    <img src={telegramfw} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/mmsplatform/" className="caption-color">
                                    <img src={twitterCopy} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://github.com/blockchain2017/MCASH" className="caption-color">
                                    <img src={githubfw} height={40} width={40} alt="bsc" />
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </footer>
            <div className='copy-rights-section'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12 d-flex justify-content-center text-white">
                            <div>Copyright © 2023 MMS Cash All Right Reserve</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
